import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import debounce from "lodash.debounce"
import { em } from "polished"
import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { DEFAULT_SHIPPING_INTERVAL } from "../../constants"
import { useStore } from "../../hooks/useStore"
import { columns } from "../../utils/columns"
import { getCustomAttributes } from "../../utils/getCustomAttributes"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { getSubscriptionDeliveryAttributes } from "../../utils/getSubscriptionDeliveryAttributes"
import {
  formatPrice,
  getDiscountPercent,
  getDiscountPriceRaw,
} from "../../utils/money"
import { parseGid } from "../../utils/parseGid"
import { updateCustomAttributes } from "../../utils/updateCustomAttributes"
import {
  StyledQuantityField,
  StyledQuantityFieldControl,
  StyledQuantityFieldInput,
} from "../Buttons/StyledButton"
import { Select, StyledSelect } from "../Form/Select"
import Icon from "../Icon/Icon"
import { Pill, StyledPill } from "../Pill/Pill"
import { ScreenReaderText } from "../ScreenReaderText"
import { CartItemNote, StyledCartItemNote } from "./CartItemNote"
import { CartItemUpsell, StyledCartItemUpsell } from "./CartItemUpsell"
import RecurringOrderNote, {
  StyledRecurringOrderNote,
} from "./RecurringOrderNote"
import { StyledCartItemPrice } from "./StyledCartItem"

const CartPageStyledQuantityField = styled(StyledQuantityField)`
  padding: 0 0.5em;
`

const CartPageStyledQuantityFieldInput = styled(StyledQuantityFieldInput)`
  @media only screen and (max-width: 48em) {
    padding: 0.3em 0;
    margin: 0;
  }
`

const CartPageStyledQuantityFieldControl = styled(StyledQuantityFieldControl)`
  padding: 0.25em 0.25em;
`

const StyledCartItem = styled.section`
  min-height: 160px;
  border: 2px solid var(--color-italian-greyhound);
  border-radius: 6px;
  padding: 1em 0.5em;
  margin-bottom: ${em(24)};
  @media only screen and (min-width: 48em) {
    padding: ${em(30)} ${em(24)};
  }
`

const StyledPriceDetailsWrapper = styled.div`
  margin-left: auto;
  grid-area: 1 / 2;

  > * {
    margin: 0px;
  }

  ${StyledSelect} {
    display: none;
  }

  ${StyledRecurringOrderNote} {
    display: none;
  }

  @media only screen and (min-width: 48em) {
    display: grid;
    grid-template-columns: 1fr max-content;

    column-gap: ${em(24)};
    margin-top: 0;
    grid-area: 1 / 2;

    ${StyledPill} {
      display: inline-block;
      flex-shrink: 0;
    }
  }

  @media only screen and (min-width: 64em) {
    ${StyledSelect} {
      display: block;
      > div {
        justify-content: flex-end;
      }
    }

    ${StyledRecurringOrderNote} {
      display: block;
      grid-column: 1 / span 2;
      max-width: 455px;
      margin-right: 5.5em;
      margin-top: 0;
    }

    ${StyledPill} + div {
      min-width: 64px;
    }
  }
`

const StyledCartItemInner = styled.div`
  display: grid;
  column-gap: ${em(8)};

  > label {
    margin-top: ${em(8)};
    display: inline-block;
  }

  @media only screen and (min-width: 64em) {
    grid-auto-flow: column;
    grid-template-columns: auto auto max-content;
  }

  ${StyledCartItemUpsell} + ${StyledPriceDetailsWrapper} {
    margin-left: auto;
    width: fit-content;
    max-width: none;
    align-items: flex-start;
  }

  @media only screen and (min-width: 48em) {
    column-gap: 0;

    ${StyledCartItemUpsell} + ${StyledPriceDetailsWrapper} {
      grid-area: initial;
    }
  }

  ${StyledCartItemNote} + ${StyledPriceDetailsWrapper} {
    margin-left: auto;
    width: fit-content;
    max-width: none;
    align-items: flex-start;
  }

  @media only screen and (min-width: 48em) {
    ${StyledCartItemNote} + ${StyledPriceDetailsWrapper} {
      grid-area: initial;
    }
  }
`

const StyledProductWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex: 1;
  .gatsby-image-wrapper {
    flex-shrink: 0;
    max-width: 60px;
  }

  @media only screen and (min-width: 48em) {
    .gatsby-image-wrapper {
      max-width: 100px;
    }
  }
`

const StyledCartItemControlsWrap = styled.div`
  @media only screen and (min-width: 30em) {
    ${StyledSelect} {
      flex-basis: 100%;
    }
  }

  @media only screen and (min-width: 64em) {
    ${StyledSelect} {
      display: none;
    }
  }
`

const StyledCartItemTitle = styled.div`
  color: var(--color-irish-wolfhound);

  > h2 {
    margin: 0;
    font-size: 16px;

    span {
      font-size: 14px;
      font-weight: var(--font-normal);
    }

    @media only screen and (min-width: 30em) {
      font-size: 18px;
    }

    @media only screen and (min-width: 64em) {
      font-size: 24px;
    }
  }

  > p:last-child {
    margin: 0;
  }

  @media only screen and (min-width: 64em) {
    > h2 {
      font-size: ${em(24)};
    }
  }
`

const StyledCartItemControls = styled.div`
  margin-top: ${em(8)};
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;

  > label {
    margin: 0;
  }

  ${StyledQuantityFieldControl} {
    color: var(--color-great-dane);
  }

  @media only screen and (min-width: 745px) {
    flex-direction: row;
    align-items: center;
  }
`

const StyledRemoveButton = styled.button`
  border: none;
  background-color: #fff;
  font-size: 16px;
  color: var(--color-beet);
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
    border-radius: 3px;
    color: var(--color-earth);
  }

  &:hover {
    color: var(--color-earth);
  }
`

function getSelectedShippingInterval(attrs = []) {
  return attrs.find(attr => attr.key === "shipping_interval")
}

function CartItem({
  variant,
  id,
  title,
  quantity: defaultQuantity,
  customAttributes,
}) {
  const quantityNode = useRef()
  const [quantity, setQuantity] = useState(defaultQuantity)

  const [shippingInterval, setShippingInterval] = useState(
    () =>
      getSelectedShippingInterval(customAttributes)?.value ||
      DEFAULT_SHIPPING_INTERVAL
  )

  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity])

  const {
    checkout,
    updateLineItems,
    removeLineItems,
    getProductByVariantMatch,
    getVariant,
  } = useStore()

  const lineItemProduct = getProductByVariantMatch({ storefrontId: variant.id })
  const lineItemVariant = getVariant(
    { storefrontId: variant.id },
    lineItemProduct.variants
  )

  const getAttributeValue = getAttributeByKey(customAttributes)

  const updateLineItem = debounce(
    quantity => updateLineItems(checkout.id, [{ id, quantity }]),
    300
  )

  const handleQuantityChange = e => {
    const quantity = Number(e.target.value)
    if (quantity < 1) {
      return
    }

    setQuantity(quantity)
    updateLineItem(quantity)
  }

  const handleQuantityDecrement = e => {
    e.preventDefault()
    const q = quantity - 1
    const nextQuantity = q >= 1 ? q : 1

    setQuantity(nextQuantity)
    updateLineItem(nextQuantity)
  }

  const handleQuantityIncrement = e => {
    e.preventDefault()
    const nextQuantity = quantity + 1

    setQuantity(nextQuantity)
    updateLineItem(nextQuantity)
  }

  const handleRemoveLineItem = async e => {
    e.preventDefault()
    window.dataLayer = window.dataLayer || []

    // Elevar base datalayer for Add to Cart
    window.dataLayer.push({
      event: "dl_remove_from_cart",
      event_id: uuidv4(),
      ecommerce: {
        currencyCode: checkout.currencyCode,
        remove: {
          actionField: {
            list: "",
          },
          products: [
            {
              name: lineItemProduct.title,
              id: (lineItemVariant && lineItemVariant.sku) || "",
              product_id: parseGid(lineItemProduct.shopifyId) || "",
              variant_id: parseGid(lineItemVariant.shopifyId) || "",
              image: lineItemProduct?.featuredImage?.originalSrc || "",
              price: lineItemVariant.price,
              brand: lineItemProduct.vendor,
              variant: lineItemProduct.hasOnlyDefaultVariant
                ? lineItemProduct.title
                : lineItemVariant.displayName,
              category: lineItemProduct.productType,
              quantity: [],
              list: "",
            },
          ],
        },
      },
    })

    const shouldCascade = getAttributeValue("cascade_delete")
    if (shouldCascade === "true") {
      const cascadePrimaryKey = getAttributeValue("cascade_primary_key")
      const cascadeDeleteItems = checkout.lineItems
        .filter(lineItem => {
          const getAttributeValue = getAttributeByKey(lineItem.customAttributes)
          const cascadeReferenceKey = getAttributeValue("cascade_reference_key")
          return cascadeReferenceKey === cascadePrimaryKey
        })
        .map(lineItem => lineItem.id)
        .flat()

      const pendingRemoval = cascadeDeleteItems.concat([id])
      return await removeLineItems(checkout.id, pendingRemoval)
    }

    return await removeLineItems(checkout.id, [id])
  }

  const handleShippingIntervalSelection = async e => {
    const interval = e.target.value

    const attributes = getCustomAttributes(customAttributes)
    const attributesByShippingInterval =
      getSubscriptionDeliveryAttributes(interval)

    const updatedAttributes = updateCustomAttributes(
      attributes,
      attributesByShippingInterval
    )

    setShippingInterval(interval)
    await updateLineItems(checkout.id, [
      { id, quantity, customAttributes: updatedAttributes },
    ])
  }

  const variantImage = {
    ...variant.image,
    originalSrc: variant.image.src,
  }

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        width: columns(1),
        height: columns(1),
      }),
    [variantImage.src]
  )

  /**
   * Need to normalize cart item names because some product variants have rogue titles generated by
   * ReCharge and sometimes our own team. We want a friendly, human readable title without misc. info.
   *
   * @param {string} str name of cart item
   * @returns string
   */
  const getFriendlyProductTitle = (str = "") => {
    const pos = str
      .toLowerCase()
      .search(/- subscription|auto renew|subscription|([0-9]+%)|\(([^)]+)\)/gi)
    return pos !== -1 ? str.slice(0, pos).trim() : str
  }

  const isSubscription = getAttributeValue("shipping_interval")
  const hasSubscriptionVariant = getAttributeValue("discount_variant_id")
  const isFreebie = Number(variant.priceV2.amount) === 0
  const hasDisableOrderCustomizationAttribute = getAttributeValue(
    "disable_order_customization"
  )

  const inlineNotes = getAttributeValue("line_item_notes")
  const subscription_original_price = getAttributeValue("original_price")
  const subscription_discount_value = getAttributeValue(
    "subscription_discount_value"
  )

  // value used for scenarios where line items are discounted without any additional
  // coupon codes applied to first time purchases
  const discountPercentWithoutFtp = getDiscountPercent(
    subscription_original_price,
    lineItemVariant.price
  )

  // value used for scenarios where line items are discounted with the addition of a
  // coupon code for first time purchases
  const discountPercentWithFtp = getDiscountPercent(
    subscription_original_price,
    getDiscountPriceRaw(lineItemVariant.price, subscription_discount_value)
  )

  const discountPercent = !!subscription_discount_value
    ? discountPercentWithFtp
    : discountPercentWithoutFtp

  const originalPrice = formatPrice(subscription_original_price)
  const lineItemPrice = formatPrice(lineItemVariant.price)

  return (
    <StyledCartItem>
      <StyledCartItemInner>
        <StyledProductWrapper>
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? variant.title}
            width={columns(1)}
            height={columns(1)}
            quality={100}
          />
          <StyledCartItemControlsWrap>
            <StyledCartItemTitle>
              <h2>{getFriendlyProductTitle(title)} </h2>
              <p>
                <small>{variant.title.replace("Default Title", "")}</small>
              </p>
            </StyledCartItemTitle>
            <StyledCartItemControls>
              {!hasDisableOrderCustomizationAttribute && (
                <CartPageStyledQuantityField>
                  <ScreenReaderText>Product quantity</ScreenReaderText>
                  <CartPageStyledQuantityFieldControl
                    onClick={handleQuantityDecrement}
                  >
                    <ScreenReaderText>
                      Decrement quantity by one
                    </ScreenReaderText>
                    <Icon name="subtraction" height={14} width={14} />
                  </CartPageStyledQuantityFieldControl>
                  <CartPageStyledQuantityFieldInput
                    ref={quantityNode}
                    type="number"
                    min={1}
                    pattern="[0-9]*"
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  <CartPageStyledQuantityFieldControl
                    onClick={handleQuantityIncrement}
                  >
                    <ScreenReaderText>
                      Increment quantity by one
                    </ScreenReaderText>
                    <Icon name="addition" height={14} width={14} />
                  </CartPageStyledQuantityFieldControl>
                </CartPageStyledQuantityField>
              )}
              <StyledRemoveButton onClick={handleRemoveLineItem}>
                Remove
              </StyledRemoveButton>
              {isSubscription && !hasDisableOrderCustomizationAttribute && (
                <Select
                  orientation="horizontal"
                  textColor="var(--color-great-dane)"
                  value={shippingInterval}
                  onChange={handleShippingIntervalSelection}
                  label="Deliver Every"
                  options={[
                    { children: "2 weeks", value: "2 weeks" },
                    { children: "3 weeks", value: "3 weeks" },
                    { children: "4 weeks", value: "4 weeks" },
                    { children: "5 weeks", value: "5 weeks" },
                    { children: "6 weeks", value: "6 weeks" },
                    { children: "7 weeks", value: "7 weeks" },
                    { children: "8 weeks", value: "8 weeks" },
                  ]}
                />
              )}
            </StyledCartItemControls>
          </StyledCartItemControlsWrap>
        </StyledProductWrapper>
        <CartItemNote content={inlineNotes} />
        {hasSubscriptionVariant && !isFreebie && (
          <CartItemUpsell
            from={id}
            product={lineItemProduct}
            variant={lineItemVariant}
            customAttributes={customAttributes}
          />
        )}
        <StyledPriceDetailsWrapper>
          {isSubscription && !hasDisableOrderCustomizationAttribute ? (
            <>
              <Select
                orientation="horizontal"
                textColor="var(--color-great-dane)"
                value={shippingInterval}
                onChange={handleShippingIntervalSelection}
                label="Deliver Every"
                options={[
                  { children: "2 weeks", value: "2 weeks" },
                  { children: "3 weeks", value: "3 weeks" },
                  { children: "4 weeks", value: "4 weeks" },
                  { children: "5 weeks", value: "5 weeks" },
                  { children: "6 weeks", value: "6 weeks" },
                  { children: "7 weeks", value: "7 weeks" },
                  { children: "8 weeks", value: "8 weeks" },
                ]}
              />
              <RecurringOrderNote
                discountPercent={discountPercentWithoutFtp}
                discountPrice={lineItemPrice}
                originalPrice={originalPrice}
                shippingInterval={shippingInterval}
                withAdditionalFtpDiscount={!!subscription_discount_value}
              />
              <StyledCartItemPrice hasStrikeThrough>
                <Pill backgroundColor="var(--color-green)" color="#ffffff">
                  {discountPercent} off
                </Pill>
                <div>
                  <p>{originalPrice}</p>
                  <p>
                    {formatPrice(
                      subscription_discount_value
                        ? getDiscountPriceRaw(
                            lineItemVariant.price,
                            subscription_discount_value
                          )
                        : lineItemVariant.price
                    )}
                  </p>
                </div>
              </StyledCartItemPrice>
            </>
          ) : (
            <StyledCartItemPrice>
              <p>{lineItemPrice}</p>
            </StyledCartItemPrice>
          )}
        </StyledPriceDetailsWrapper>
      </StyledCartItemInner>
      {isSubscription && !hasDisableOrderCustomizationAttribute && (
        <RecurringOrderNote
          discountPercent={discountPercentWithoutFtp}
          discountPrice={lineItemPrice}
          originalPrice={originalPrice}
          shippingInterval={shippingInterval}
          withAdditionalFtpDiscount={!!subscription_discount_value}
        />
      )}
    </StyledCartItem>
  )
}

export default CartItem
