import { em } from "polished"
import React, { useState } from "react"
import styled from "styled-components"
import {
  DEFAULT_SHIPPING_INTERVAL,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import { useStore } from "../../hooks/useStore"
import { getAttributedVariant } from "../../utils/getAttributedVariant"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { formatPrice, getDiscountPriceRaw } from "../../utils/money"
import { StyledButton, StyledButtonMessage } from "../Buttons/StyledButton"
import { Select } from "../Form/Select"
import Spinner from "../Spinner/Spinner"

export const StyledCartItemUpsell = styled.div`
  padding: ${em(20)} ${em(40)};
  background-color: #f2f3f4;
  grid-area: 2 / span 2;
  margin-top: ${em(24)};

  @media only screen and (min-width: 48em) {
    padding: ${em(20)} ${em(40)};
    margin-top: ${em(60)};
  }

  @media only screen and (min-width: 64em) {
    padding: 20px 9px 20px 18px;
    margin-top: 0;
    grid-area: initial;
    justify-self: flex-end;
    width: 455px;
  }
`

const StyledCartItemUpsellInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 493px;
  margin-inline: auto;
  justify-content: space-between;

  div:first-child {
    flex-shrink: 0;

    h3 {
      font-size: ${em(18)};
      color: var(--color-green);
    }

    label {
      display: block;
    }
  }

  > p {
    span {
      font-weight: var(--font-bold);
    }
  }

  @media only screen and (min-width: 48em) {
    flex-direction: row;
    align-items: center;
    gap: 1em;
    > p {
      max-width: 190px;
    }
  }
`

const StyledSwapItemButton = styled(StyledButton)`
  padding: ${em(6)} 1em;
  border-radius: 0;
  font-size: 14px;
`

export function CartItemUpsell({ from, customAttributes, variant, product }) {
  const { checkout, replaceLineItem } = useStore()
  const [updating, setUpdating] = useState(false)

  const [shippingInterval, setShippingInterval] = useState(
    DEFAULT_SHIPPING_INTERVAL
  )

  const getMetafieldValue = getAttributeByKey(variant.metafields)
  const discountVariantPrice = getMetafieldValue("discount_variant_price")

  const getAttributeValue = getAttributeByKey(customAttributes)
  const subscription_discount_code = getAttributeValue(
    "subscription_discount_code"
  )
  const subscription_discount_value = getAttributeValue(
    "subscription_discount_value"
  )

  const handleReplaceLineItem = async () => {
    try {
      setUpdating(true)
      await replaceLineItem(checkout.id, {
        from,
        to: getAttributedVariant(
          product,
          variant,
          1,
          PURCHASE_TYPE_SUBSCRIPTION,
          shippingInterval,
          {
            code: subscription_discount_code,
            value: subscription_discount_value,
          },
          customAttributes
        ),
      })
    } catch (e) {
      console.error(e)
      setUpdating(false)
    }
  }

  const handleShippingIntervalSelection = async e => {
    const interval = e.target.value
    setShippingInterval(interval)
  }

  const price = subscription_discount_value
    ? getDiscountPriceRaw(discountVariantPrice, subscription_discount_value)
    : discountVariantPrice

  return (
    <StyledCartItemUpsell>
      <StyledCartItemUpsellInner>
        <div>
          <h3>Subscribe &amp; Save</h3>
          <Select
            orientation="horizontal"
            textColor="var(--color-great-dane)"
            value={shippingInterval}
            onChange={handleShippingIntervalSelection}
            label="Deliver"
            options={[
              { children: "every 2 weeks", value: "2 weeks" },
              { children: "every 3 weeks", value: "3 weeks" },
              { children: "every 4 weeks", value: "4 weeks" },
              { children: "every 5 weeks", value: "5 weeks" },
              { children: "every 6 weeks", value: "6 weeks" },
              { children: "every 7 weeks", value: "7 weeks" },
              { children: "every 8 weeks", value: "8 weeks" },
            ]}
          />
          <StyledSwapItemButton onClick={handleReplaceLineItem}>
            {updating ? (
              <StyledButtonMessage>
                <Spinner height={16} width={16} color="#ffffff" /> Updating to
                subscription
              </StyledButtonMessage>
            ) : (
              <>Change to subscription</>
            )}
          </StyledSwapItemButton>
        </div>
        <p>
          Get <strong>free shipping</strong> and save an additional{" "}
          <strong>{formatPrice(Number(variant.price - price))}</strong> today.
        </p>
      </StyledCartItemUpsellInner>
    </StyledCartItemUpsell>
  )
}
