export function getSubscriptionDeliveryAttributes(
  interval,
  customAttributes = []
) {
  const frequency = parseInt(interval, 10)
  const unit = interval.replace(/[0-9]|s$/gi, "").trim()

  return customAttributes.concat([
    { key: "charge_interval_frequency", value: String(frequency) },
    { key: "shipping_interval", value: interval },
    { key: "shipping_interval_frequency", value: String(frequency) },
    { key: "shipping_interval_unit", value: unit },
    { key: "shipping_interval_unit_type", value: unit },
  ])
}
