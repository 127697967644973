import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  PURCHASE_TYPE_ONETIME,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import { useAllShopifyCollection } from "../../hooks/useAllShopifyCollection"
import { useStore } from "../../hooks/useStore"
import { useToggle } from "../../hooks/useToggle"
import { parseGid } from "../../utils/parseGid"
import DrawerUpsell from "../DrawerUpsell/DrawerUpsell"
import CarouselItem from "./CarouselItem"

const CarouselWrap = styled.div`
  height: 272px;
  background-color: var(--color-italian-greyhound);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;

  > h2 {
    font-size: 18px;
    color: var(--color-great-dane);
    font-weight: 900;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 768px) {
    height: 365px;
    > h2 {
      font-size: 34px;
    }
  }

  @media only screen and (min-width: 1024px) {
    height: 365px;
    > h2 {
      font-size: 36px;
    }
  }
`

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: var(--color-italian-greyhound);
`

const Container = styled.div`
  background-color: var(--color-italian-greyhound);
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    width: 85%;
  }
`

const ArrowButton = styled.button`
  display: none;
  background-color: transparent;

  &:focus {
    outline: none;
    box-shadow: var(--color-italian-greyhound) 0 0 0 2px,
      var(--color-great-dane) 0 0 0 4px;
  }
  &:hover {
    background-color: var(--color-great-dane);
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-irish-wolfhound);
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
`

function Carousel({ hasSubscriptions }) {
  const graphqlResult = useAllShopifyCollection()

  const slider = useRef()
  const [productDrawer, setProductDrawer] = useState()
  const { checkout, getProductByVariantMatch, getVariant } = useStore()
  const [on, toggle] = useToggle()

  const [{ products }] = graphqlResult?.allShopifyCollection?.nodes

  const variants = products.reduce((acc, curr) => {
    if (curr.variants.length > 1) {
      return acc.concat(curr.variants)
    }

    const [product] = curr.variants
    return acc.concat({
      ...product,
      cardImage: curr.cardImage,
      drawerImage: curr.drawerImage,
    })
  }, [])

  let notIncludedInCartVariants = variants.filter(variant => {
    let present = false
    checkout.lineItems.forEach(lineItem => {
      if (lineItem.variant.sku === variant.sku) {
        present = true
      }
    })

    return !present
  })

  useEffect(() => {
    if (checkout?.currencyCode && notIncludedInCartVariants?.length > 0) {
      const datalayerImpressions = notIncludedInCartVariants.map(
        (variant, idx) => {
          const lineItemProduct = getProductByVariantMatch({
            storefrontId: variant.storefrontId,
          })

          const lineItemVariant = getVariant(
            { storefrontId: variant.storefrontId },
            lineItemProduct.variants
          )

          return {
            position: idx + 1,
            name: lineItemProduct.title,
            id: (lineItemVariant && lineItemVariant.sku) || "",
            product_id: parseGid(lineItemProduct.shopifyId) || "",
            variant_id: parseGid(lineItemVariant.shopifyId) || "",
            image: lineItemProduct?.featuredImage?.originalSrc || "",
            price: lineItemVariant.price,
            brand: lineItemProduct.vendor,
            variant: lineItemProduct.hasOnlyDefaultVariant
              ? lineItemProduct.title
              : lineItemVariant.displayName,
            category: lineItemProduct.productType,
          }
        }
      )

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "dl_view_item_list",
        event_id: uuidv4(), // unique uuid for FB conversion API
        ecommerce: {
          currencyCode: checkout.currencyCode,
          impressions: datalayerImpressions,
        },
      })
    }
  }, [])

  let infinityToggle = true

  if (notIncludedInCartVariants.length < 3) {
    infinityToggle = false
  } else {
    infinityToggle = true
  }

  const settings = {
    dots: false,
    variableWidth: true,
    adaptiveHeight: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 1,
    centerMode: true,
    arrows: false,
    accessibility: true,
    swipeToSlide: true,
    initialSlide: 1,
    centerPadding: "350px",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 735,
        settings: {
          slidesToShow: 1,
          centerPadding: "170px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          centerPadding: "15px",
        },
      },
    ],
  }

  return (
    <>
      <CarouselWrap className="carousel">
        <h2>Add something extra for your pup</h2>
        <CarouselContainer>
          <ArrowButton onClick={() => slider?.current?.slickPrev()}>
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="25.6"
                height="25.6"
                transform="translate(0.599609 0.600006)"
              />
              <path
                d="M21.9329 12.3334H8.95154L14.9142 6.37073L13.3995 4.86673L4.86621 13.4001L13.3995 21.9334L14.9035 20.4294L8.95154 14.4667H21.9329V12.3334Z"
                fill="white"
              />
            </svg>
          </ArrowButton>
          <Container>
            <Slider ref={slider} {...settings}>
              {notIncludedInCartVariants.map(variant => (
                <CarouselItem
                  key={variant.id}
                  variant={variant}
                  setProductDrawer={setProductDrawer}
                  openProductDrawer={toggle}
                  purchaseType={
                    hasSubscriptions
                      ? PURCHASE_TYPE_SUBSCRIPTION
                      : PURCHASE_TYPE_ONETIME
                  }
                />
              ))}
            </Slider>
          </Container>
          <ArrowButton onClick={() => slider?.current?.slickNext()}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.0681 9.6666H13.0494L7.08677 15.6293L8.60143 17.1333L17.1348 8.59994L8.60143 0.0666046L7.09743 1.5706L13.0494 7.53327H0.0681V9.6666Z"
                fill="white"
              />
            </svg>
          </ArrowButton>
        </CarouselContainer>
      </CarouselWrap>
      <DrawerUpsell
        on={on}
        toggle={toggle}
        variant={productDrawer}
        purchaseType={
          hasSubscriptions ? PURCHASE_TYPE_SUBSCRIPTION : PURCHASE_TYPE_ONETIME
        }
      />
    </>
  )
}

export default Carousel
