import { graphql, useStaticQuery } from "gatsby"
import { em } from "polished"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import SlideDown from "../components/Animations/SlideDown"
import Banner, { StyledBanner } from "../components/Banner/Banner"
import ButtonCheckout from "../components/Buttons/ButtonCheckout"
import Carousel from "../components/Carousel/Carousel"
import CarouselToggle from "../components/Carousel/CarouselToggle"
import CartItems from "../components/CartItems/CartItems"
import CartSummary from "../components/CartSummary/CartSummary"
import EmptyCart from "../components/EmptyCart"
import Seo from "../components/seo"
import { useMediaQuery } from "../hooks/useMediaQuery"
import { useStore } from "../hooks/useStore"
import { useToggle } from "../hooks/useToggle"
import PageLayout from "../layouts/PageLayout"
import { columns } from "../utils/columns"

const StyledCart = styled.div`
  max-width: calc(${columns(10)}px + (${em(20)} * 2));
  margin: 0 auto;
  padding: ${em(20)};

  @media only screen and (min-width: 48em) {
    max-width: calc(${columns(10)}px + (${em(24)} * 2));
    padding: ${em(24)};
  }

  > ${StyledBanner} {
    margin-bottom: ${em(24)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    h1 {
      font-size: 1rem;
      font-weight: var(--font-normal);
      color: inherit;
    }

    div:last-child {
      display: flex;
      background-color: transparent;
      padding: 0;
      flex: none;
    }
  }
`

function CartPage() {
  const graphqlResult = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          shopifyId
          variants {
            price
            shopifyId
            storefrontId
            product {
              shopifyId
            }
            metafields {
              key
              value
            }
            weight
            weightUnit
          }
        }
      }
    }
  `)

  const products = graphqlResult?.allShopifyProduct?.nodes.reduce(
    (acc, node) => {
      const { variants, shopifyId } = node
      const newVariants = variants.map(v => ({ ...v, product: { shopifyId } }))
      return [...acc, ...newVariants]
    },
    []
  )

  const { checkout, isUpdating } = useStore()
  const [isRechargeOrder, setRechargeOrder] = useState(false)
  const [isFreeShipping, setFreeShipping] = useState(false)
  const [on, toggle] = useToggle()

  const isTablet = useMediaQuery("(min-width: 48em)")

  const defaultSlideDownStyles = {
    transition: `margin 300ms ease-in-out`,
    marginTop: isTablet ? "-365px" : "-272px",
  }

  const slideDownTransitionStyles = {
    entering: { marginTop: "0" },
    entered: { marginTop: "0" },
    exiting: { marginTop: isTablet ? "-365px" : "-272px" },
    exited: { marginTop: isTablet ? "-365px" : "-272px" },
  }

  useEffect(() => {
    // determine whether the checkout cart has subscription items
    // to be processed by ReCharge Payments, this will help when needing to generate
    // a ReCharge checkout URL
    const isRecharge = checkout.lineItems.some(lineItem =>
      lineItem.customAttributes.find(attr => attr.key === "shipping_interval")
    )

    const isFreeShipping = checkout.lineItems.every(lineItem =>
      lineItem.customAttributes.find(attr => attr.key === "shipping_interval")
    )

    setFreeShipping(isFreeShipping)
    setRechargeOrder(isRecharge)
  }, [checkout.lineItems])

  return (
    <PageLayout>
      <Seo title="Cart" />
      {checkout.lineItems.length ? (
        <>
          <SlideDown
            open={on}
            defaultStyles={defaultSlideDownStyles}
            transitionStyles={slideDownTransitionStyles}
          >
            <Carousel hasSubscriptions={isRechargeOrder} />
          </SlideDown>
          <StyledCart>
            <Banner>
              <h1>Your Order</h1>
              <div>
                <CarouselToggle handleClick={toggle}>
                  + Add more items
                </CarouselToggle>
              </div>
            </Banner>
            <CartItems lineItems={checkout.lineItems} />
            <CartSummary
              products={products}
              total={checkout.paymentDueV2}
              lineItems={checkout.lineItems}
              hasSubscriptionLineItems={isRechargeOrder}
              isFreeShipping={isFreeShipping}
              subtotal={checkout.subtotalPriceV2}
            />
            <ButtonCheckout
              isUpdating={isUpdating}
              isRechargeCheckout={isRechargeOrder}
            />
          </StyledCart>
        </>
      ) : (
        <EmptyCart />
      )}
    </PageLayout>
  )
}

export default CartPage
