import { graphql, useStaticQuery } from "gatsby"

export function useAllShopifyCollection() {
  const graphqlResult = useStaticQuery(graphql`
    {
      allShopifyCollection(filter: { title: { eq: "Upsells" } }) {
        nodes {
          products {
            variants {
              metafields {
                key
                value
              }
              price
              displayName
              id
              shopifyId
              storefrontId
              sku
              product {
                shopifyId
                metafields {
                  key
                  value
                }
              }
              cardImage: image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 68, height: 68)
                  }
                }
                altText
              }
              drawerImage: image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 450, height: 450)
                  }
                }
                altText
              }
            }
            cardImage: featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 68, height: 68)
                }
              }
              altText
            }
            drawerImage: featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 450, height: 450)
                }
              }
              altText
            }
          }
        }
      }
    }
  `)

  return graphqlResult
}
