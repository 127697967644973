import { em } from "polished"
import * as React from "react"
import styled from "styled-components"
import { ButtonLink } from "./Buttons"

const StyledWrapper = styled.div`
  text-align: center;
  padding: 3em ${em(24)};
`

function EmptyCart() {
  return (
    <StyledWrapper>
      <h1>Shopping Cart</h1>
      <p>Your cart is currently empty.</p>
      <ButtonLink alignment="center" text="Keep shopping" url="/shop" />
    </StyledWrapper>
  )
}

export default EmptyCart
