import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { DEFAULT_SHIPPING_INTERVAL } from "../../constants"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"
import { StyledSVG } from "../Icon/Icon"

const StyledCarouselItemContainer = styled.section`
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
  margin: 0 4px 10px;
  height: 164px;
  width: 335px;
  display: flex;
  background: #fff;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 5px;

    > img {
      width: 62px;
      height: 62px;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 379px;
    margin: 20px 12px;
    height: 186px;
  }

  ${StyledSVG} {
    width: 12px;
    height: 12px;
  }
`

const StyledCartItemTitle = styled.h2`
  color: var(--color-irish-wolfhound);
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin: 0px;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
`

const StyledProductDetailsButton = styled.button`
  color: var(--color-wild-blue);
  border: none;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 0px;
  margin: 0px 0px 10px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin: 0px 0px 20px;
  }
`

function CarouselItem({
  variant,
  purchaseType,
  setProductDrawer,
  openProductDrawer,
}) {
  const { id, cardImage, displayName } = variant
  let trimmedDisplayName

  if (displayName.includes("- Default Title")) {
    trimmedDisplayName = displayName.replace("- Default Title", "").trim()
  } else if (displayName.includes("Treats")) {
    trimmedDisplayName =
      displayName
        .replace("Superfood Dog Treats With Koji (5oz) - ", "")
        .trim() + " Treats"
  }

  return (
    <>
      <StyledCarouselItemContainer key={id}>
        <div>
          {cardImage && (
            <GatsbyImage
              style={{
                width: "57px",
                height: "57px",
              }}
              image={cardImage.localFile.childImageSharp.gatsbyImageData}
              alt={displayName}
            />
          )}
        </div>
        <div>
          <StyledCartItemTitle>{trimmedDisplayName}</StyledCartItemTitle>
          <div>
            <StyledProductDetailsButton
              onClick={() => {
                setProductDrawer(variant)
                openProductDrawer()
              }}
            >
              View Product Details
            </StyledProductDetailsButton>
            <ButtonAddToCart
              showQuantity={true}
              size="small"
              text="Add to Order"
              variant={variant}
              product={variant.product}
              interval={DEFAULT_SHIPPING_INTERVAL}
              purchaseType={purchaseType}
              hasViewItemDataLayer={false}
            />
          </div>
        </div>
      </StyledCarouselItemContainer>
    </>
  )
}

export default CarouselItem
