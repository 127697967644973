import React from "react"
import styled from "styled-components"
import { CartItem } from "../CartItem"

const StyledCartItems = styled.div`
  grid-area: content;
  flex: 1;
`

function CartItems({ lineItems }) {
  return (
    <StyledCartItems>
      {lineItems.map(item => (
        <CartItem key={item.id} {...item} />
      ))}
    </StyledCartItems>
  )
}

export default CartItems
