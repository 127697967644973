export function updateCustomAttributes(source = [], incoming = []) {
  return source.map(({ key, value }) => {
    const currentAttribute = incoming.find(
      o => o.key === key && o.value !== value
    )

    if (!!currentAttribute) {
      return Object.assign({}, { key, value }, currentAttribute)
    }

    return { key, value }
  })
}
