import { graphql, useStaticQuery } from "gatsby"
import { em } from "polished"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { WEIGHT_OUNCES_TO_POUNDS } from "../../constants"
import { useStore } from "../../hooks/useStore"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { formatPrice, getDiscountPriceRaw } from "../../utils/money"
import { parseGid } from "../../utils/parseGid"
import Banner, { StyledBanner } from "../Banner/Banner"
import Icon from "../Icon/Icon"
import { Pill, StyledPill } from "../Pill/Pill"

const StyledCartItemsSummaryInner = styled.div`
  display: grid;
  gap: ${em(48)} ${em(24)};
  grid-template-columns: 1fr auto;
  justify-content: flex-end;
  text-align: right;

  p {
    margin: 0;
  }

  > :nth-last-child(-n + 2) {
    margin-top: 20px;
  }
`

const StyledCartItemsSummary = styled.div`
  grid-area: footer;

  ${StyledBanner} {
    border-radius: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 24px;
    flex-grow: 0;

    p {
      display: flex;
      align-items: center;

      > svg:first-child {
        margin-right: 0.5em;
        flex-shrink: 0;
      }
    }

    @media only screen and (min-width: 40em) {
      display: none;
    }
  }

  ${StyledBanner} + p {
    flex-shrink: 0;
  }
`

const StyledPrice = styled.p`
  font-size: 18px;
`

const StyledBannerHeading = styled.div`
  ${StyledBanner} {
    display: none;
  }

  @media only screen and (min-width: 40em) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    + p {
      align-self: center;
    }

    ${StyledBanner} {
      display: flex;
      margin: 0 3.5em 0 0;
      max-width: 440px;
    }
  }
`

const StyledStrikethroughWrap = styled.div`
  font-size: 18px;

  p:first-child {
    color: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "var(--color-pointer)" : "var(--color-great-dane)"};
    text-decoration: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "line-through" : "none"};
  }

  p:last-child {
    text-transform: uppercase;
    color: var(--color-green);
    font-weight: var(--font-bold);
  }

  p:only-child {
    font-weight: var(--font-normal);
    color: var(--color-great-dane);
  }
`

const CartSummaryShippingRate = styled.div`
  ${StyledPill} {
    position: absolute;
    right: 1.25em;
    display: inline-block;
    padding: 6px 10px;
    margin-top: 18px;
  }

  @media only screen and (min-width: 48em) {
    ${StyledPill} {
      position: initial;
      display: inline-block;
      margin-top: 8px;
    }
  }
`

/**
 * Calculate the combined weight of cart line items
 *
 * Used for calculating the flat-rate shipping fee
 *
 * @returns {number} weight in lbs
 */
const getCartWeight = (lineItems, products) => {
  const totalWeight = lineItems.reduce((acc, curr) => {
    const product = products.find(p => p.storefrontId === curr.variant.id)
    const weightInPounds =
      product.weightUnit === "OUNCES"
        ? curr.variant.weight / WEIGHT_OUNCES_TO_POUNDS
        : curr.variant.weight
    return Number(curr.quantity * weightInPounds) + acc
  }, 0)

  return totalWeight
}

/**
 * Retrieve a flat rate shipping fee based on a hard coded definition of shipping weights and flat rate fees
 *
 * @param {number} w weight of each cart line item multiplied by it's quantity
 * @param {array} rate array of shopify shipping rates
 * @returns {number} flat rate shipping fee for the calculated weight
 */
const getCartShippingFee = (w, rate) => {
  const shippingRate = rate.find(r =>
    r.weight_high ? w >= r.weight_low && w <= r.weight_high : w >= r.weight_low
  )

  return Number(shippingRate?.price) || 0
}

const getAdditionalSavings = items => {
  return items.reduce((acc, item) => {
    const getAttributeValue = getAttributeByKey(item.customAttributes)
    const subscription = getAttributeValue("shipping_interval")
    if (!subscription) {
      return acc + 0
    }

    const discount = getAttributeValue("subscription_discount_value") || 0
    const delta = getDiscountPriceRaw(item.variant.priceV2.amount, discount)
    const savings =
      (Number(item.variant.priceV2.amount) - Number(delta)) *
      Number(item.quantity)
    return acc + savings
  }, 0)
}

function CartSummary({ isFreeShipping, total, subtotal, lineItems, products }) {
  const { checkout, getProductByVariantMatch, getVariant } = useStore()
  const graphqlResult = useStaticQuery(graphql`
    {
      allShippingZone {
        nodes {
          name
          weight_based_shipping_rates {
            weight_low
            weight_high
            price
          }
        }
      }
    }
  `)

  const shopifyShippingRates =
    graphqlResult.allShippingZone.nodes.find(n => n.name === "Domestic")
      ?.weight_based_shipping_rates || []

  const lineItemsWithShippingFee = lineItems.filter(item => {
    const getAttributeValue = getAttributeByKey(item.customAttributes)
    const isSubscription = getAttributeValue("shipping_interval")
    const isFreeTrial = getAttributeValue("disable_order_customization")
    const isFreeTrialOrOTP = !isSubscription || isFreeTrial
    return isFreeTrialOrOTP
  })

  const accumulativeShippingCharges = getCartShippingFee(
    getCartWeight(lineItems, products),
    shopifyShippingRates
  )

  const onetimePurchaseShippingCharges = getCartShippingFee(
    getCartWeight(lineItemsWithShippingFee, products),
    shopifyShippingRates
  )

  const ShippingFees = () =>
    isFreeShipping ? (
      <>
        <p>{formatPrice(accumulativeShippingCharges)}</p>
        <p>Free</p>
      </>
    ) : (
      <p>{formatPrice(onetimePurchaseShippingCharges)}</p>
    )

  // the prices visually reflected on each line item are either represented by the product retrieved from Shopify SDK
  // or the discount variant price retrieved from the line items custom attributes
  //
  // in order to accurately reflect the cart total and subtotal, we have to subtract any discounts that are derived from
  // the line items custom attributes from the cart total retrieved from Shopify SDK
  const savings = getAdditionalSavings(lineItems)
  const cartTotal = Number(total.amount) - savings

  // cart subtotal changes based on whether we the entire cart is a subscription order
  // since shipping is free for subscription products, the cart subtotal will sometimes
  // include shipping fees
  const cartSubtotal = isFreeShipping
    ? Number(subtotal.amount) - savings
    : Number(subtotal.amount) - savings + onetimePurchaseShippingCharges

  useEffect(() => {
    if (checkout.lineItems.length > 0) {
      const datalayerImpressions = checkout.lineItems.map(
        ({ variant, quantity }, idx) => {
          const lineItemProduct = getProductByVariantMatch({
            storefrontId: variant.id,
          })

          const lineItemVariant = getVariant(
            { storefrontId: variant.id },
            lineItemProduct.variants
          )

          return {
            position: idx + 1,
            name: lineItemProduct.title,
            id: (lineItemVariant && lineItemVariant.sku) || "",
            product_id: parseGid(lineItemProduct.shopifyId) || "",
            variant_id: parseGid(lineItemVariant.shopifyId) || "",
            image: lineItemProduct?.featuredImage?.originalSrc || "",
            price: lineItemVariant.price,
            brand: lineItemProduct.vendor,
            variant: lineItemProduct.hasOnlyDefaultVariant
              ? lineItemProduct.title
              : lineItemVariant.displayName,
            category: lineItemProduct.productType,
            quantity: quantity,
          }
        }
      )

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "dl_view_cart",
        event_id: uuidv4(), // unique uuid for FB conversion API
        cart_total: cartSubtotal,
        ecommerce: {
          currencyCode: checkout.currencyCode,
          actionField: { list: "Shopping Cart" },
          impressions: datalayerImpressions,
        },
      })
    }
  }, [])

  return (
    <StyledCartItemsSummary>
      <Banner
        backgroundColor="var(--color-great-dane)"
        style={{ maxWidth: "390px" }}
      >
        <p className="has-text-color has-white-color">
          <Icon name="published-with-changes" fill="#FFFFFF" />
          Change or cancel subscription anytime
        </p>
      </Banner>
      <StyledCartItemsSummaryInner>
        <StyledBannerHeading>
          <Banner
            backgroundColor="var(--color-great-dane)"
            style={{ maxWidth: "390px", wrap: "wrap" }}
          >
            <p className="has-text-color has-white-color">
              <Icon name="published-with-changes" fill="#FFFFFF" />
              Change or cancel subscription anytime
            </p>
          </Banner>
          <p>Cart Total</p>
        </StyledBannerHeading>
        <StyledPrice>{formatPrice(cartTotal)}</StyledPrice>
        <CartSummaryShippingRate>
          <p>Flat-Rate Shipping</p>
          {!isFreeShipping && (
            <Pill backgroundColor="var(--color-green)" color="#ffffff">
              Subscribe to get free shipping
            </Pill>
          )}
        </CartSummaryShippingRate>
        <StyledStrikethroughWrap hasStrikeThrough={isFreeShipping}>
          <ShippingFees />
        </StyledStrikethroughWrap>
        <p>Subtotal</p>
        <StyledPrice>
          <strong>{formatPrice(cartSubtotal)}</strong>
        </StyledPrice>
      </StyledCartItemsSummaryInner>
    </StyledCartItemsSummary>
  )
}

CartSummary.propTypes = {
  total: PropTypes.object.isRequired,
  subtotal: PropTypes.object.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object),
}

export default CartSummary
