import { getCustomAttributes } from "./getCustomAttributes"
import { getAttributeByKey } from "./getLineItemAttributes"

function mapCustomAttributesToRechargeProperties(attributes = []) {
  if (!attributes.length) {
    return []
  }

  const formattedAttributes = Object.assign(
    ...attributes.map(({ key, value }) => ({
      [key]: value,
    }))
  )

  return [
    {
      charge_interval_frequency: Number(
        formattedAttributes.charge_interval_frequency
      ),
    },
    {
      order_interval_frequency: Number(
        formattedAttributes.shipping_interval_frequency
      ),
    },
    { order_interval_unit: formattedAttributes.shipping_interval_unit },
  ]
}

export function getRechargeCheckoutObject(
  lineItems,
  analytics,
  note_attributes,
  ...rest
) {
  const line_items = lineItems.map(lineItem => {
    const getAttributeValue = getAttributeByKey(lineItem.customAttributes)

    return Object.assign(
      {},
      {
        quantity: lineItem.quantity,
        // Why are we using ORIGINAL product IDs here instead of SUBSCRIPTION product IDs?
        // Well...
        // According to Gustavo from ReCharge...
        //
        // Can you please confirm whether you're using the product and variant IDs from the original product, or from the auto renew copy
        // that Recharge creates?
        //
        // Looking up for instances in which this error occurred, I found some info from our API team informing that using the ID from the
        // auto renew copy would cause this error, so they advise about using the ID from the original product.
        product_id: getAttributeValue("original_product_id"),
        variant_id: getAttributeValue("original_variant_id"),
      },
      mapCustomAttributesToRechargeProperties(
        getCustomAttributes(lineItem.customAttributes)
      ).reduce((r, c) => Object.assign(r, c), {})
    )
  })

  // What's happening here?
  // in order to attach a discount code to a ReCharge checkout, we have to find the discount that was added to any one of the
  // cart line item as a custom attribute... why?
  // Because discount codes/values flow from the top most layer of data (cms.wildearth.com) and down into the buybox and then eventually
  // the cart page. In order to carry that discount code along for the ride, we must attach to the the cart line item.
  const discountCode = lineItems.reduce((acc, lineItem) => {
    const getAttributeValue = getAttributeByKey(lineItem.customAttributes)
    const discount = getAttributeValue("subscription_discount_code")
    if (discount) {
      acc = discount
    }

    return acc
  }, "")

  return {
    line_items,
    // ensure that if there aren't any `analytic` properties that we don't add an `analytics_data` property by accident
    // this could trigger ReCharge's strict argument checking and prevent the checkout from generating
    ...(Object.values(analytics || {}).length && {
      analytics_data: {
        ...analytics,
      },
    }),
    ...(Object.values(note_attributes || {}).length && {
      ...note_attributes,
    }),
    ...(discountCode && { discount_code: discountCode }),
    ...rest,
  }
}
