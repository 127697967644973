import { em } from "polished"
import React from "react"
import styled from "styled-components"
import { parseHtml } from "../../utils/parseHtml"

export const StyledCartItemNote = styled.div`
  background-color: #f2f3f4;
  padding: ${em(20)} ${em(30)};
  grid-area: 2 / span 2;
  margin-top: ${em(24)};

  p {
    color: #13294b;
    font-size: 14px;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    color: #13294b;
    font-size: 14px;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 48em) {
    padding: ${em(20)} ${em(30)};
    margin-top: ${em(60)};
  }

  @media only screen and (min-width: 64em) {
    margin-top: 0;
    grid-area: initial;
  }
`

export function CartItemNote({ content }) {
  if (content?.length) {
    return <StyledCartItemNote>{parseHtml(content)}</StyledCartItemNote>
  }

  return null
}
