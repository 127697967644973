import { em } from "polished"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import Icon from "../Icon/Icon"
import Portal from "../Portal/Portal"
import { StyledBackdrop } from "./StyledDrawer"

const StyledDrawer = styled.aside`
  position: fixed;
  height: 100%;
  top: 0;
  background-color: #ffffff;
  overflow-y: auto;
  width: 100%;
  z-index: 2;
  padding: ${em(20)};
  @media only screen and (min-width: 48em) {
    max-width: 1140px;
    padding: 2.5em;
  }
`

const StyledDrawerButtonClose = styled.button`
  color: var(--color-irish-wolfhound);
  cursor: pointer;
  display: block;
  margin-left: auto;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
  &:hover {
    color: var(--color-great-dane);
  }

  svg {
    height: 28px;
    width: 28px;
  }
`

const defaultFadeInStyles = {
  transition: `opacity 300ms ease-in-out`,
  opacity: 0,
}

const defaultSlideInStyles = {
  transition: `left 300ms ease-in-out`,
  left: "-100%",
}

const fadeInTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const slideInTransitionStyles = {
  entering: { left: "0", transitionDelay: `300ms` },
  entered: { left: "0" },
  exiting: { left: "-100%" },
  exited: { left: "-100%" },
}

function Drawer({ children, duration, open, onClose, ...rest }) {
  const node = useRef()

  const handleClose = () => onClose()

  const handleClickOutside = e => {
    if (node.current && !node.current.contains(e.target)) {
      handleClose()
    }
  }

  return (
    <Portal>
      <Transition
        in={open}
        timeout={duration}
        onEnter={() => {
          document.body.style.overflow = "hidden"
        }}
        onExited={() => {
          document.body.style.overflow = ""
        }}
        appear
        unmountOnExit
      >
        {state => (
          <StyledBackdrop
            {...rest}
            style={{
              ...defaultFadeInStyles,
              ...fadeInTransitionStyles[state],
            }}
            onClick={handleClickOutside}
          >
            <Transition
              in={state === "entering" || state === "entered"}
              timeout={duration}
            >
              {state => (
                <StyledDrawer
                  ref={node}
                  style={{
                    ...defaultSlideInStyles,
                    ...slideInTransitionStyles[state],
                  }}
                >
                  <StyledDrawerButtonClose onClick={handleClose}>
                    <Icon name="close" />
                  </StyledDrawerButtonClose>
                  {children}
                </StyledDrawer>
              )}
            </Transition>
          </StyledBackdrop>
        )}
      </Transition>
    </Portal>
  )
}

Drawer.defaultProps = {
  duration: 300,
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}

export default Drawer
