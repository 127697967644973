import { GatsbyImage } from "gatsby-plugin-image"
import { em } from "polished"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { DEFAULT_SHIPPING_INTERVAL } from "../../constants"
import { columns } from "../../utils/columns"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { parseHtml } from "../../utils/parseHtml"
import { ButtonAddToCart } from "../Buttons"
import { StyledButtonWrap } from "../Buttons/StyledButton"
import Drawer from "../Drawer/Drawer"

const StyledDrawerHeader = styled.section`
  padding: ${em(38)} 0;

  @media only screen and (min-width: 48em) {
    display: flex;
    gap: 2em;
    padding: ${em(56)} 0;
    border-bottom: 2px solid var(--color-gray);
    h2 {
    }

    ${StyledButtonWrap} {
    }

    > div:first-child {
    }

    > div:last-child {
      flex: 1 1 ${columns(4)}px;
    }
  }
`

const StyledDrawerSection = styled.section`
  padding: 2.5em 0;
  border-bottom: 2px solid var(--color-gray);
  @media only screen and (min-width: 48em) {
    display: flex;
    gap: ${em(24)};

    > :first-child {
      flex-basis: ${columns(3)}px;
    }

    > :last-child {
      flex: 1;
    }
  }
`

function DrawerUpsell({ variant, purchaseType, on, toggle }) {
  const {
    displayName,
    drawerImage,
    product: { metafields },
  } = variant

  const getAttributeValue = getAttributeByKey(metafields)

  const html = {
    [getAttributeValue("benefitshead")]: getAttributeValue("benefits"),
    description: getAttributeValue("description"),
    [getAttributeValue("ingredientshead")]: getAttributeValue("ingredients"),
    [getAttributeValue("nutritionhead")]: getAttributeValue("nutrition"),
    [getAttributeValue("outrohead")]: getAttributeValue("outro"),
  }

  return (
    <Drawer open={on} onClose={toggle}>
      <StyledDrawerHeader>
        {drawerImage && (
          <GatsbyImage
            image={drawerImage.localFile.childImageSharp.gatsbyImageData}
            alt={displayName}
          />
        )}
        <div>
          <h2>{displayName}</h2>
          <ButtonAddToCart
            variant={variant}
            product={variant.product}
            purchaseType={purchaseType}
            interval={DEFAULT_SHIPPING_INTERVAL}
            text="Add to Order"
            afterAddedText="✔︎ Added"
            showQuantity
          />
        </div>
      </StyledDrawerHeader>
      {Object.entries(html).map(([heading, content]) => {
        return (
          content && (
            <StyledDrawerSection>
              <header>
                <h2>{heading}</h2>
              </header>
              <div>{parseHtml(content)}</div>
            </StyledDrawerSection>
          )
        )
      })}
    </Drawer>
  )
}

DrawerUpsell.defaultProps = {
  variant: {
    storefrontId: "",
    shopifyId: "",
    metafields: [],
    product: {
      shopifyId: "",
    },
  },
}

DrawerUpsell.propTypes = {
  variant: PropTypes.shape({
    displayName: PropTypes.string,
    drawerImage: PropTypes.object,
    product: PropTypes.shape({
      storefrontId: PropTypes.string,
      shopifyId: PropTypes.string,
      metafields: PropTypes.array,
    }),
  }),
}

export default DrawerUpsell
