export function getRechargeCheckoutUrl(token, utms = []) {
  const url = process.env.GATSBY_APP_RECHARGE_CHECKOUT_URL
  const domain = process.env.GATSBY_APP_STOREFRONT_DOMAIN
  const checkoutUrl = url
    .replace("{{token}}", token)
    .replace("{{shopify_domain}}", domain)

  if (utms.length) {
    const checkoutParams = utms
      .map(
        ({ name, value }) =>
          `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
      )
      .join("&")

    return /\?.+=.*/.test(checkoutUrl)
      ? `${checkoutUrl}&${checkoutParams}`
      : `${checkoutUrl}?${checkoutParams}`
  }

  return checkoutUrl
}
