import { em } from "polished"
import PropTypes from "prop-types"
import styled from "styled-components"

export const StyledCartItemPrice = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  p {
    margin: 0;
  }
  p:first-child {
    line-height: 1;
    color: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "var(--color-pointer)" : "var(--color-great-dane)"};
    text-decoration: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "line-through" : "none"};
  }

  @media only screen and (min-width: 48em) {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
    gap: ${em(24)};

    grid-column: 2;
    grid-row: 1;
  }

  @media only screen and (min-width: 64em) {
    min-width: 64px;
  }
`

StyledCartItemPrice.defaultProps = {
  hasStrikeThrough: false,
}

StyledCartItemPrice.propTypes = {
  hasStrikeThrough: PropTypes.bool.isRequired,
}
