import { em } from "polished"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { StyledButton } from "../Buttons/StyledButton"

const StyledCarouselToggle = styled(StyledButton)`
  border-radius: 2em;
  font-size: 14px;
  padding: ${em(10)} ${em(32)};
  background-color: var(--color-staffie);
  border-color: var(--color-staffie);
  &:focus {
    box-shadow: #fff 0 0 0 2px, var(--color-staffie) 0 0 0 4px;
  }
  &:hover {
    border-color: var(--color-irish-wolfhound);
    background-color: var(--color-irish-wolfhound);
  }
`

function CarouselToggle({ handleClick }) {
  return (
    <StyledCarouselToggle onClick={handleClick}>
      + Add more items
    </StyledCarouselToggle>
  )
}

CarouselToggle.defaultProps = {
  handleClick: () => null,
}

CarouselToggle.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default CarouselToggle
