import React from "react"
import styled from "styled-components"

export const StyledRecurringOrderNote = styled.div`
  padding: 18px 16px;
  background-color: var(--color-ivory);
  margin-top: 20px;

  h3 {
    font-size: 18px;
  }

  @media only screen and (min-width: 64em) {
    display: none;
  }
`

function RecurringOrderNote({
  withAdditionalFtpDiscount,
  discountPercent,
  discountPrice,
  originalPrice,
  shippingInterval,
}) {
  if (!withAdditionalFtpDiscount) {
    return null
  }

  const flattenMoney = (str = "") => {
    const pos = str.toLowerCase().search(/\.00/gi)
    return pos !== -1 ? str.slice(0, pos).trim() : str
  }

  return (
    <StyledRecurringOrderNote>
      <h3>Recurring orders:</h3>
      <p>
        Automatic discount of {discountPercent} off the retail price (
        {flattenMoney(originalPrice)}). Your subscription price will be{" "}
        {flattenMoney(discountPrice)} every {shippingInterval}.
      </p>
    </StyledRecurringOrderNote>
  )
}

export default RecurringOrderNote
