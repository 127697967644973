import React, { useState } from "react"
import styled from "styled-components"
import { COOKIES_TO_NOTES } from "../../constants"
import { useCookies } from "../../hooks/useCookies"
import { useStore } from "../../hooks/useStore"
import { columns } from "../../utils/columns"
import { getRechargeCheckoutObject } from "../../utils/getRechargeCheckoutObject"
import { getRechargeCheckoutUrl } from "../../utils/getRechargeCheckoutUrl"
import Spinner from "../Spinner/Spinner"
import { StyledButton, StyledButtonMessage } from "./StyledButton"

export const StyledButtonCheckout = styled(StyledButton)`
  margin-left: auto;
  margin-top: 2em;
  display: block;
  border-radius: 0;
  max-width: ${columns(2)}px;
  width: 100%;

  &[disabled] {
    border-color: var(--color-cool-gray);
    background-color: var(--color-cool-gray);
    color: var(--color-great-dane);
  }
`

function formatCookiesToRechargeNotes(cookies) {
  return cookies.map(cookie => {
    const [name] = Object.keys(cookie)
    const [value] = Object.values(cookie)

    return {
      name,
      value,
    }
  })
}

function formatCookiesToShopifyNotes(cookies) {
  return cookies.map(cookie => {
    const [key] = Object.keys(cookie)
    const [value] = Object.values(cookie)

    return {
      key,
      value,
    }
  })
}

function ButtonCheckout({ isUpdating, isRechargeCheckout }) {
  const { client, checkout } = useStore()
  const [loading, setLoading] = useState(false)
  const { getCookie, getUTMs } = useCookies()

  const getCookiesAndAliases = () => {
    return COOKIES_TO_NOTES.map(
      ([name, alias, options = { named: true }, fn]) => {
        const cookie = fn
          ? fn(name, getCookie(name, options))
          : getCookie(name, options)

        if (!cookie || !cookie[name]) {
          return null
        }

        return alias ? [cookie, { [alias]: cookie[name] }] : [cookie]
      }
    )
      .flat()
      .filter(Boolean)
  }

  const handleCheckoutRedirect = () => {
    const utms = getUTMs()
    const cookieMap = getCookiesAndAliases()
    const getShopifyCheckoutUrl = async () => {
      const shopifyNotes = formatCookiesToShopifyNotes(cookieMap)

      // add custom attributes (e.g Notes have additional properties section we can add info to)
      // Retrieve the UTM value from the native shopify cookie and convert it into an array that is compatible with
      // Shopify's customAttributes list
      const formattedUTMs = Object.entries(utms || {}).map(([key, value]) => ({
        key,
        value,
      }))

      const payload = await client.checkout.updateAttributes(checkout.id, {
        customAttributes: [...formattedUTMs, ...shopifyNotes],
      })

      const checkoutUrl = payload.webUrl.replace(
        process.env.GATSBY_APP_STOREFRONT_DOMAIN,
        process.env.GATSBY_APP_SHOPIFY_DOMAIN
      )

      if (formattedUTMs.length) {
        const checkoutParams = formattedUTMs
          .map(
            ({ key, value }) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&")

        return /\?.+=.*/.test(checkoutUrl)
          ? `${checkoutUrl}&${checkoutParams}`
          : `${checkoutUrl}?${checkoutParams}`
      }

      return checkoutUrl
    }

    /**
     * What's happening here?
     * In order to redirect a customer to the appropriate checkout page (e.g. Shopify vs ReCharge)
     * we need to first build a `checkout` object. This object simply contains X number of key/value pairs that
     * correspond to an order's line items, line items subscription frequency, price, quantity., etc
     * After building the `checkout` object, we pass that data over to ReCharge to retrieve a Checkout Token
     * this token allows us to then redirect the customer to the Checkout page.
     *
     * @returns {string} ReCharge checkout URL
     */
    const getCheckoutUrl = async () => {
      const rechargeNotes = formatCookiesToRechargeNotes(cookieMap)
      const formattedUTMs = Object.entries(utms || {}).map(([name, value]) => ({
        name,
        value,
      }))

      const note_attributes = [...formattedUTMs, ...rechargeNotes]

      const res = await fetch(
        `${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API}/checkouts?key=${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API_TOKEN}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(
            getRechargeCheckoutObject(
              checkout.lineItems,
              {
                ...(!!utms && {
                  utm_params: [utms],
                }),
              },
              {
                ...(note_attributes.length && {
                  note_attributes,
                }),
              }
            )
          ),
        }
      )

      const data = await res.json()
      const token = data?.checkout?.token

      if (!token) {
        throw new Error(`Could not generate checkout token for ${checkout.id}`)
      }

      return getRechargeCheckoutUrl(token, formattedUTMs)
    }

    const handleCheckout = async () => {
      try {
        setLoading(true)
        const func = isRechargeCheckout ? getCheckoutUrl : getShopifyCheckoutUrl
        const url = await func()
        window.location = url
        return
      } catch (error) {
        setLoading(false)
        console.info(`Checkout ID: ${checkout.id}`)
        console.error(error)
      }
    }

    handleCheckout()
  }

  const working = isUpdating || loading

  return (
    <StyledButtonCheckout
      className="checkout-button"
      onClick={handleCheckoutRedirect}
      disabled={working}
    >
      {working ? (
        <StyledButtonMessage>
          <Spinner height={16} width={16} color="var(--color-great-dane)" />{" "}
          {isUpdating ? "Updating..." : "Loading..."}
        </StyledButtonMessage>
      ) : (
        <>Check Out</>
      )}
    </StyledButtonCheckout>
  )
}

export default ButtonCheckout
